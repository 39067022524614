$site-styles-font-path: 'https://assetshuluimcom-a.akamaihd.net/FONTS/' !default;

@font-face {
  font-family: 'Graphik';
  src: url('#{$site-styles-font-path}Graphik-Extralight-Web.eot');
  src: url('#{$site-styles-font-path}Graphik-Extralight-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$site-styles-font-path}Graphik-Extralight-Web.woff2') format('woff2'),
    url('#{$site-styles-font-path}Graphik-Extralight-Web.woff') format('woff');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('#{$site-styles-font-path}Graphik-Regular-Web.eot');
  src: url('#{$site-styles-font-path}Graphik-Regular-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$site-styles-font-path}Graphik-Regular-Web.woff2') format('woff2'),
    url('#{$site-styles-font-path}Graphik-Regular-Web.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('#{$site-styles-font-path}Graphik-Medium-Web.eot');
  src: url('#{$site-styles-font-path}Graphik-Medium-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$site-styles-font-path}Graphik-Medium-Web.woff2') format('woff2'),
    url('#{$site-styles-font-path}Graphik-Medium-Web.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('#{$site-styles-font-path}Graphik-Semibold-Web.eot');
  src: url('#{$site-styles-font-path}Graphik-Semibold-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$site-styles-font-path}Graphik-Semibold-Web.woff2') format('woff2'),
    url('#{$site-styles-font-path}Graphik-Semibold-Web.woff') format('woff');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('#{$site-styles-font-path}Graphik-Bold-Web.eot');
  src: url('#{$site-styles-font-path}Graphik-Bold-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$site-styles-font-path}Graphik-Bold-Web.woff2') format('woff2'),
    url('#{$site-styles-font-path}Graphik-Bold-Web.woff') format('woff');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('#{$site-styles-font-path}Graphik-Regular-Web.eot');
  src: url('#{$site-styles-font-path}Graphik-Regular-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$site-styles-font-path}Graphik-Regular-Web.woff2')
      format('woff2'),
    url('#{$site-styles-font-path}Graphik-Regular-Web.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('#{$site-styles-font-path}Graphik-Medium-Web.eot');
  src: url('#{$site-styles-font-path}Graphik-Medium-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$site-styles-font-path}Graphik-Medium-Web.woff2')
      format('woff2'),
    url('#{$site-styles-font-path}Graphik-Medium-Web.woff')
      format('woff');
  font-weight: 500;

  /*  The Graphik font doesn't have a fixed hight for a single size, so
  ascent-override is necessary to center the 500 font-weight */
  ascent-override: 90%;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('#{$site-styles-font-path}Graphik-Semibold-Web.eot');
  src: url('#{$site-styles-font-path}Graphik-Semibold-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$site-styles-font-path}Graphik-Semibold-Web.woff2')
      format('woff2'),
    url('#{$site-styles-font-path}Graphik-Semibold-Web.woff')
      format('woff');
  font-weight: 600;
  font-style: normal;
}

// Brand font
@font-face {
  font-family: 'HuluStyle';
  src: url('#{$site-styles-font-path}hulustyle/upright/hulustyle-regular-webfont.woff2')
      format('woff2'),
    url('#{$site-styles-font-path}hulustyle/upright/hulustyle-regular-webfont.woff')
      format('woff');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HuluStyle';
  src: url('#{$site-styles-font-path}hulustyle/upright/hulustyle-extrabold-webfont.woff2')
      format('woff2'),
    url('#{$site-styles-font-path}hulustyle/upright/hulustyle-extrabold-webfont.woff')
      format('woff');
  font-weight: 800;
  font-display: swap;
}
