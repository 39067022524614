@import './mixins';
@import './colors';

html {
  box-sizing: border-box;
  height: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: $body-background;
  font-family: 'Graphik', Helvetica, Arial, sans-serif;
  margin: 0px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h3 {
  color: $black-4;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  font-weight: normal;
  padding-top: 8px;
  padding-bottom: 8px;
}

a {
  cursor: pointer;
  &, &:active, &:hover, &:focus {
    text-decoration: none;
    color: $text-link-3;
  }
}

button {
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  border-radius: 0;
}

button:focus {
  cursor: pointer;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

footer {
  width: 100%;
}

h1 {
  margin-top: 0;
}

img {
  vertical-align: middle;
  border-style: none;
  &.resp-img {
    width: 100%;
    height: auto;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}

input {
  -webkit-appearance: none;
  font-size: 16px;
  line-height: 1.5;
  color: $input-color;
  border-radius: 0;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $gray-22;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $gray-22;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $gray-22;
}
:-moz-placeholder { /* Firefox 18- */
  color: $gray-22;
}
